import React, { useState, useEffect, useRef } from "react";
import SendImg from "../images/send.png";
import UserImage from "../images/sended.png";
import BotImage from "../images/receiver.png";
import axios from "axios";
import { Container, Row, Col, Form, Card } from "react-bootstrap";
import Cookies from "js-cookie";

function Chatbot() {
  const chatEndRef = useRef(null);
  const expirationTime = new Date();
  expirationTime.setTime(expirationTime.getTime() + 8 * 60 * 60 * 1000);

  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState("");
  const [isTyping, setIsTyping] = useState(false);

  useEffect(() => {
    const savedMessages = Cookies.get("chatMessages");
    if (savedMessages) {
      setMessages(JSON.parse(savedMessages));
    }
  }, []);

  useEffect(() => {
    if (chatEndRef.current) {
      chatEndRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [messages]);

  const handleInputChange = (event) => {
    setUserInput(event.target.value);
  };

  const handleKeyDown = (event) => {
    if (event.key === "Enter") {
      event.preventDefault();
      handleSendMessage();
    }
  };

  /// API calling function
  const handleSendMessage = async () => {
    if (userInput.trim() === "") return;

    const updatedMessages = [...messages, { text: userInput, sender: "user" }];

    setMessages(updatedMessages);

    Cookies.set("chatMessages", JSON.stringify(updatedMessages), {
      expires: expirationTime,
    });

    setIsTyping(true);

    const formData = new FormData();
    formData.append("user_input", userInput);

    setUserInput("");
    try {
      const response = await axios.post(
        "https://business-planner.brainvire.net/business_planner/",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );

      const botMessage = {
        text: response.data.data.response,
        sender: "bot",
      };

      const newMessages = [...updatedMessages, botMessage];

      setMessages(newMessages);

      Cookies.set("chatMessages", JSON.stringify(newMessages), {
        expires: expirationTime,
      });
    } catch (error) {
      console.error("Error fetching data from API:", error);
    } finally {
      setIsTyping(false);
    }
  };

  return (
    <Container>
      <Row className="justify-content-center mt-5">
        <Col xs={12} md={8} lg={8}>
          <Card style={{ border: "none" }}>
            <Card.Body>
              <div className="chat_card">
                {messages.map((msg, index) => (
                  <div
                    key={index}
                    className={`d-flex mb-3 ${
                      msg.sender === "user"
                        ? "justify-content-end"
                        : "justify-content-start"
                    }`}
                  >
                    {msg.sender === "bot" && (
                      <img
                        src={BotImage}
                        alt="Bot Avatar"
                        className="user-avatar"
                      />
                    )}

                    <div
                      className={`${
                        msg.sender === "user"
                          ? "user_text text-white"
                          : "user_replay"
                      }`}
                      style={{ maxWidth: "70%" }}
                      dangerouslySetInnerHTML={{
                        __html: msg.text.replace(/\n/g, "<br />"),
                      }}
                    />

                    {msg.sender === "user" && (
                      <img
                        src={UserImage}
                        alt="User Avatar"
                        className="chat-avatar"
                      />
                    )}
                  </div>
                ))}

                {isTyping && (
                  <div className="d-flex mb-3 justify-content-start">
                    <div className="user_replay" style={{ maxWidth: "70%" }}>
                      <div className="typing-dots"></div>
                    </div>
                  </div>
                )}
                <div ref={chatEndRef} />
              </div>
              <Form className="d-flex mt-3">
                <Form.Control
                  type="text"
                  placeholder="Type a message"
                  value={userInput}
                  onChange={handleInputChange}
                  onKeyDown={handleKeyDown}
                />
                {userInput.trim() && (
                  <img
                    src={SendImg}
                    alt="Send Message"
                    className="send_img"
                    onClick={handleSendMessage}
                  />
                )}
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
}

export default Chatbot;
